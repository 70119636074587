export const PORPOSAL_PLAN = [
  {
    count: "01",
    type: "チームビルディング・企業合宿",
    heading: "チームビルディングを箱根の合宿型コミュニケーション空間で",
    places: "箱根（神奈川県）",
    schedule: "1泊2日",
    guestInfo: "夕・朝食付き、20名様ご利用の場合",
    cost: "￥24,200 円～",
    numberOfPeople: "10〜20名",
    description:
      "企業合宿を箱根・仙石原の LIME RESORT HAKONEにて貸切で行う1泊2日プラン。夕・朝食が含まれ、館内ラウンジやミーティングルームなどもご自由にカスタマイズいただけます。オプションで、ワンダーイマジニア 講師：今井千尋氏による世界２大テーマパークにて培ってきた人財育成メソッド シナジーを生み出すチームビルディング研修（4時間）を追加できます。",
    gallery: [
      {
        image: "/plans/plan_1/plan1.jpg",
      },
      {
        image: "/plans/plan_1/plan2.jpg",
      },
      {
        image: "/plans/plan_1/plan3.jpg",
      },
    ],
  },
  {
    count: "02",
    type: "チームビルディング・事業部合宿・ワーケーション",
    heading: "手ぶらで楽しめるキャンプ場でチームビルディングを",
    places: "館山（千葉県）",
    schedule: "1泊2日",
    guestInfo: "夕・朝食付き、テント泊、5名様ご利用の場合",
    cost: "￥8,470 円～",
    numberOfPeople: "10名",
    gallery: [
      {
        image: "/plans/plan_2/plan1.jpg",
      },
      {
        image: "/plans/plan_2/plan2.jpg",
      },
      {
        image: "/plans/plan_2/plan3.jpg",
      },
    ],
    description:
      "キャンプ道具一式が用意されているキャンプ場でチームビルディングを行う1泊2日プラン。夕食は専用ガスグリルで本格的なBBQが堪能できます。目の前の海では海水浴や釣りなどが楽しめ、サンセットの美しさは必見です。バドミントンセットなどのスポーツグッズが無料で使用できるため、非日常を体験しながらのチームビルディングに最適です。オプションで、シーカヤック・ダイビング等の追加もできます。Wi-Fi、電源完備。",
  },
  {
    count: "03",
    type: "チームビルディング・企業合宿",
    heading: "宿坊でチームビルディング。お寺で、坐禅や瞑想、ヨガ体験も",
    places: "身延山（山梨県）",
    schedule: "1泊2日",
    guestInfo: "夕・朝食付き、10名様ご利用の場合",
    cost: "￥16,500 円～",
    numberOfPeople: "10〜30名",
    gallery: [
      {
        image: "/plans/plan_3/plan1.jpg",
      },
      {
        image: "/plans/plan_3/plan2.jpg",
      },
      {
        image: "/plans/plan_3/plan3.jpg",
      },
    ],
    description:
      "静寂の中で自分と仲間と見つめ合う時間をつくるお籠り合宿1泊2日プラン。740年   を超える歴史ある聖地の身延山は、かつては僧侶や参拝客のための宿坊でしたが、最近は国内外の一般の観光客も宿泊することができる場所となり、企業や団体の合宿にも人気を集めています。一切動物性のものを使わない精進料理（2食）の他、瞑想・写経・ヨガ体験など滞在中のアクティビティも豊富です。",
  },
  {
    count: "04",
    type: "オフサイトミーティング・幹部会議",
    heading: "オフサイトミーティングを銀座のナミキ スイートで",
    places: "銀座（東京都）",
    schedule: "日帰り",
    guestInfo: "食事なし、10名様ご利用の場合",
    cost: "￥22,000 円～",
    numberOfPeople: "8〜10名",
    gallery: [
      {
        image: "/plans/plan_4/plan1.jpg",
      },
      {
        image: "/plans/plan_4/plan2.jpg",
      },
      {
        image: "/plans/plan_4/plan3.jpg",
      },
    ],
    description:
      "銀座の街並みが一望できるハイアット セントリック 銀座 東京のナミキ スイート（127㎡）でオフサイトミーティングを行う日帰りプラン。移動に時間をかけずにオフサイトコミュニケーションを図りたい方におすすめのプランです。オプションで、ライフバランスマネジメント研究所 講師：渡部卓氏によるプロフェッショナルの仕事術セミナーをはじめ、３時間の各種研修を追加できます。",
  },

  {
    count: "05",
    type: "ウェルビーイング研修・合宿",
    heading: "自然の中に心身を投じ五感を刺激するウェルビーイング研修合宿",
    places: "安曇野（長野県）",
    schedule: "1泊2日",
    guestInfo: "夕・朝食付き、10名様ご利用の場合",
    cost: "￥36,300 円～",
    numberOfPeople: "10〜15名",
    gallery: [
      {
        image: "/plans/plan_5/plan1.jpg",
      },
      {
        image: "/plans/plan_5/plan2.jpg",
      },
      {
        image: "/plans/plan_5/plan3.jpg",
      },
    ],
    description:
      "北アルプスを望む大自然に抱かれ、人里離れた山あいに佇むカミツレの里での1泊2日プラン。日本初のビオ（オーガニック）施設でいただくお食事（2食）や、薪割・かまど体験のアクティビティ、ここでしか体験できないカミツレエキス抽出工場見学、散策などが含まれます。この時代に求められる真のウェルビーイングをテーマに会議・研修・合宿で、ビジネスに不可欠な心の豊かさと強さを高めます。",
  },
];
